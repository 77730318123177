<template>
  <v-container
    fluid
    class="height-100"
  >
    <v-row
      justify="center"
      align="center"
      class="height-100"
    >
      <v-col
        data-aos="fade-up"
        data-aos-duration="1000"
        xl="5"
        lg="5"
        md="6"
        sm="10"
        cols="10"
      >
        <v-card class="mb-10">
          <v-card-title class="d-flex justify-center">
            <img
              src="../../assets/logo.png"
              width="200px"
            />
          </v-card-title>

          <v-form
            ref="form"
            v-model="valid"
            class="login-form mx-5"
            lazy-validation
            @submit="login()"
          >
            <v-text-field
              v-model="user.username"
              :rules="[rules.required]"
              label="Nome de usuário ou e-mail"
              append-icon="mdi-account"
              outlined
              required
              autofocus
            />

            <v-text-field
              v-model="user.password"
              class="mt-2"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'mdi-lock-open' : 'mdi-lock'"
              :type="showPassword ? 'text' : 'password'"
              label="Senha"
              outlined
              required
              @click:append="showPassword = !showPassword"
            />

            <v-row
              justify="center"
              no-gutters
            >
              <v-col
                class="d-flex justify-center"
                cols="8"
              >
                <v-btn
                  block
                  large
                  :disabled="!valid || loading"
                  color="primary"
                  class="loginBtn"
                  type="submit"
                  @click="login()"
                >
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="25"
                    width="3"
                    color="white"
                  />
                  <span v-else class="white--text">
                    Entrar
                  </span>
                </v-btn>
              </v-col>

              <v-col
                cols="10"
                class="d-flex flex-column align-center mt-2"
              >
                <router-link to="/recuperar-senha" class="links">
                  <span class="mt-2" style="color: grey">
                    Esqueceu sua senha? <span class="recovery-pwd-link">Recupere aqui!</span>
                  </span>
                </router-link>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-divider />
                  </v-col>
                  <v-col cols="6" class="text-link" style="color: grey">ou entre com</v-col>
                  <v-col cols="3">
                    <v-divider />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-row justify="center" align="center">
                  <v-col cols="8">
                    <v-btn
                      large
                      color="primary"
                      outlined
                      class="social-btn"
                      @click="googleSignIn()"
                    >
                      <div class="d-flex justify-center align-center">
                        <img src="@/assets/google.png" alt="Google logo" />
                        Google
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      valid: true,
      showPassword: false,
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      },
      user: {
        username: '',
        password: ''
      }
    }
  },
  async mounted () {
    await this.verifyIsLogged()
  },
  methods: {
    login () {
      if (this.$refs.form.validate()) {
        this.loading = true
        Auth.signIn(this.user)
          .then(async (data) => {
            this.loading = false
            const profile = await this.getUserProfile(data)
            this.$store.commit('SET_USER', profile)
            this.$cookies.set('user', JSON.stringify(profile), { expires: 7 })
            this.$router.push('/dashboard')
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    // googleSignIn () {
    //   Auth.federatedSignIn({ provider: 'Google' })
    // },
    async verifyIsLogged () {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser()
        const profile = await this.getUserProfile(authenticatedUser)
        this.$store.commit('SET_USER', profile)
        this.$cookies.set('user', JSON.stringify(profile), { expires: 7 })
        if (authenticatedUser) {
          this.$router.push('/dashboard')
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
  .login-title {
    font-weight: 700;
    font-size: 3.25rem;
  }

  .login-subtitle {
    font-weight: 400;
    font-size: 1.5rem;
  }

  .loginBtn {
    min-width: 50% !important;
  }

  .links {
    text-decoration: none;
  }

  .login-form {
    padding: 25px;
  }

  .social-btn {
    width: 100%;
    letter-spacing: 0 !important;
    font-weight: bold;
  }

  .social-btn img {
    width: 25px;
    margin-right: 10px;
  }

  .recovery-pwd-link {
    color: #005E5D;
  }
</style>
